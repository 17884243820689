/*** Import / Initialisation des variables ***/

	/* Conf */
	@import '../global/settings';

/********************************************************************************/
/************************* ZONE MOTEUR / RECHERCHE  *****************************/
/********************************************************************************/

	/*** POPIN RECHERCHE ***/
		/*#popin_recherche.home_search .content {max-width:69rem; margin-right:auto; margin-left:auto;}
		#popin_recherche.home_search .content .titre_popin {display:none;}
		#popin_recherche.page_search .search_area_cont {width:100%;}*/	
	
	/*** MOTEUR COMMUN (SENSATION POPIN / POPIN RECHERCHE) ***/
		#popin_recherche #zone_moteur {max-width:100%;}
		#popin_recherche #zone_recherche {max-width:64rem; margin:auto; padding:0;}
		#popin_recherche #zone_recherche #tx_indexedsearch {padding:0; overflow:hidden; border-radius: $global-radius;}
		// #popin_recherche #zone_recherche button, #popin_recherche #zone_recherche .noVocalRecognition {bottom:1rem;}
		#popin_recherche #zone_recherche input[type="text"] {padding:1.5rem 3rem; }
		#popin_recherche #zone_recherche legend {margin:0;}
		//.is_search #zone_recherche input::-webkit-input-placeholder, #popin_recherche #zone_recherche input::-webkit-input-placeholder {color:$white;}
		//.is_search #zone_recherche input::-moz-placeholder, #popin_recherche #zone_recherche input::-moz-placeholder {color:$white; opacity:1 !important;}
		//.is_search #zone_recherche input:-moz-placeholder, #popin_recherche #zone_recherche input:-moz-placeholder {color:$white; opacity:1 !important;}
		//.is_search #zone_recherche input:-ms-input-placeholder, #popin_recherche #zone_recherche input:-ms-input-placeholder {color:$white;}
		//.is_search #zone_recherche input[placeholder], #popin_recherche #zone_recherche input[placeholder] {color:$white; opacity:1 !important;}
		
		#zone_moteur {z-index:2; max-width:45rem; background:$white; margin-top:16rem;}
		#zone_recherche {max-width:100%; width:100%; padding:1rem 3rem 1rem 2rem; position:relative; text-align:left;}
		#zone_recherche .ligne_form {margin:0;}
		#zone_recherche input[type="text"] {text-transform:uppercase; height:inherit;}
		/*#zone_recherche input::-webkit-input-placeholder {color:$cim_black;}
		#zone_recherche input::-moz-placeholder {color:$cim_black; opacity:1 !important;}
		#zone_recherche input:-moz-placeholder {color:$cim_black; opacity:1 !important;}
		#zone_recherche input:-ms-input-placeholder {color:$cim_black;}
		#zone_recherche input[placeholder] {color:$cim_black; opacity:1 !important;}*/

		/* Bouton "OK" (lancer la recherche) */
			#zone_recherche input[type="submit"] {text-indent:-99999em; padding:0; line-height:0.000001rem; border:0;}
			#zone_recherche input[type="submit"]:hover, #zone_recherche input[type="submit"]:focus {}

		/* Bouton "Micro" (recherche vocale) */
			#zone_recherche button.vocalRecognitionOn {opacity:1 !important;}
			#zone_recherche button.vocalRecognitionOn::before, #zone_recherche button.vocalRecognitionOn::after {content:""; box-shadow:0 0 0 0.1rem transparentize($black, 0.5); position:absolute; top:50%; left:50%; margin:-3.5rem 0 0 -3.5rem; width:7rem; height:7rem; border-radius:50%; opacity:0; pointer-events:none; animation-name:anim-effect-vocalRecognitionOn-2, anim-effect-vocalRecognitionOn-1; animation-duration:1.2s; animation-iteration-count:infinite; animation-timing-function:ease-out; animation-fill-mode:forwards;}
			#zone_recherche button.vocalRecognitionOn::after {animation-delay:0.6s;}
			
			@keyframes anim-effect-vocalRecognitionOn-1 {
				0%, 100% {opacity:0;}
				40%, 60% {opacity:1;}
			}
			@keyframes anim-effect-vocalRecognitionOn-2 {
				0% {transform:scale3d(0.5, 0.5, 1);}
				100% {transform:scale3d(1.1, 1.1, 1);}
			}
			
		/* Bouton alternatif si recherche vocale non supportee (Firefox) */
			#zone_recherche button, #zone_recherche .noVocalRecognition {position:absolute; right:0; height:5.3rem; width:5rem; display:block; background-color:transparent; background-repeat:no-repeat; background-position:center center; cursor:pointer; transition:0.3s ease; background-size:2.2rem auto; border-radius:0 1rem 1rem 0; top: 0;}
			#zone_recherche button {@include micro($full_black); background-color:$yellow;}
			#zone_recherche input[type="submit"].noVocalRecognition {text-indent:0; color:$black; background:$yellow; width:5rem; height:5.6rem; right:0; top:0;}
			#zone_recherche input[type="submit"].noVocalRecognition:hover, #zone_recherche input[type="submit"].noVocalRecognition:focus {background:$dark_yellow; box-shadow:inset 0 0 0 0.2rem $cim_black;}
			#zone_recherche button:hover, #zone_recherche button:focus {box-shadow:inset 0 0 0 0.2rem $cim_black;}
	
		/* Suggestions */
			#suggestions {padding-top:0; margin:auto; overflow:hidden; max-width:64rem;}
			#suggestions .suggestions_wording {margin-bottom:1.5rem;}
			#suggestions .titre_suggestions {text-transform:uppercase; color:$cim_black; font-family:$raleway_extrabold; font-size:2em; line-height:1; padding:3rem 0 0 0; margin:0;}
			#suggestions ul {margin:0;}
			#suggestions li {margin:auto; font-size:1.4em; line-height:1.2;}
			#suggestions li.ui-state-focus {background:none;}
			#suggestions li a {padding:1rem 0; text-align:left; border-bottom:0.1rem solid $cim_medium_gray; box-shadow:none;}
			#suggestions li a span {}
			#suggestions li a:hover, #suggestions li a:focus {background-color:$yellow;}
			#suggestions li a .categories {font-size:0.8rem; text-transform:uppercase;}
			#suggestions .btn_lien {margin:1.5rem 0 0 0;}
		
			
/********************************************************************************/
/******************************** RESPONSIVE ************************************/
/********************************************************************************/

	@media screen and #{breakpoint(large down)} {}

	@media screen and #{breakpoint(medium down)} {
	}
	@media screen and #{breakpoint(small down)} {
		#popin_recherche #zone_recherche input[type='text'] {font-size:1.4em; padding:1.7rem 6rem 1.7rem 1rem;}
		#suggestions .titre_suggestions {font-size:1.6em;}
	}
